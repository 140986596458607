<template>
  <div class="h-screen">
    <!-- Verification actions -->
    <div class="flex-col-center justify-center">
      <div class="w-full my-5 md:my-20">
        <div class="absolute top-5 right-5">
          <SelectLangs />
        </div>

        <div class="w-full mx-auto sm:max-w-md mb-7">
          <Brand size="normal" />
        </div>

        <div class="w-full mx-auto sm:max-w-md px-5 sm:p-0">
          <Card>
            <template #content>
              <BlockUI :blocked="!formUserValues.successful" :baseZIndex="100" :autoZIndex="false">
                <BlockUILoader v-if="!formUserValues.successful" :text="$t('loadingData')" />
                <div class="flex-col-center mb-6">
                  <Icon icon="fxemoji:lock" class="w-32 h-32" />
                </div>
                <p class="text-sm font-normal text-gray-500 mb-3">{{ $t("verification.lastStepPart1", { email: user?.email }) }}</p>
                <p class="text-sm font-normal text-gray-500 mb-3">{{ $t("verification.lastStepPart2") }}</p>

                <Button @click="resendEmailConfirmation" type="button" :label="$t('verification.resendEmailConfirmation')" :loading="form.busy" iconPos="right" class="p-button-primary shadow w-full" />

                <!-- Form success message -->
                <div v-if="form.recentlySuccessful" class="mt-3">
                  <Message severity="success" :closable="false">{{ $t("verification.emailConfirmationResent") }}</Message>
                </div>
              </BlockUI>
            </template>
          </Card>
        </div>

        <!-- Close session and go to login -->
        <div class="flex-col-center mt-3">
          <Button @click="logout" :label="$t('navbar.logOut')" type="button" class="p-button-secondary p-button-text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Framework components
import { Icon } from "@iconify/vue"
import Card from "primevue/card"
import Button from "primevue/button"
import BlockUI from "primevue/blockui"
import Message from "primevue/message"
import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
// App components
import Brand from "@/components/Brand"
import SelectLangs from "@/components/SelectLangs"
import BlockUILoader from "@/components/UI/BlockUILoader"

export default {
  name: "Verification",
  components: {
    // Framework components
    Icon,
    Card,
    Button,
    BlockUI,
    Message,

    // App components
    Brand,
    SelectLangs,
    BlockUILoader
  },

  data() {
    return {
      token: this.$route.query?.access_token || localStorage.getItem("Token"),
      user: {},
      form: new FormClient({}),
      formUserValues: new FormClient({})
    }
  },

  mounted() {
    if (!this.token || this.token == "null") {
      this.$router.push({ name: "Login" })
    }

    this.login(this.token)
  },

  methods: {
    async login(token) {
      await this.$store.dispatch("Auth/setToken", token)
      const response = await this.formUserValues.get(ApiRoute.auth.profile)
      await this.$store.dispatch("Auth/setUser", response.data)
      this.user = response.data.data
    },

    logout() {
      localStorage.removeItem("Token")
      this.$router.push({ name: "Login" })
    },

    resendEmailConfirmation() {
      this.form.get(ApiRoute.register.confirmation + this.user.id)
    }
  }
}
</script>
